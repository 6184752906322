import React from "react"

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"


const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      maxWidth: '200px',
    }
  }
}));


const ContactForm = () => {
  const classes = useStyles();

  const [contactType, setContactType] = React.useState('');

  const handleChange = (event) => {
    setContactType(event.target.value);
  };


  return (
    <div className={classes.grid}>
      <form
        data-contact-form
        className="rd-mailform offset-top-40"
        action='https://crm.zoho.com/crm/WebToLeadForm'
        name='WebToLeads3088978000000795850'
        method='POST'
        acceptCharset='UTF-8'
      >
        <input 
          type='hidden'
          name='xnQsjsdp'
          defaultValue='f61c1e83dc842d254a4de264854d9f75b7590209d9a6b6c13305b921e2e11a69'/>
        <input type='hidden' name='zc_gad' id='zc_gad' defaultValue=''/>
        <input type='hidden' name='xmIwtLD' defaultValue='c75a31430ced4b17780d29e0c5c4ff567e054ce2b2ef8c7016f602dc132a6fc8'/>
        <input type='hidden' name='actionType' defaultValue='TGVhZHM='/>
        <input type='hidden' name='returnURL' defaultValue='https&#x3a;&#x2f;&#x2f;www.acquireinteractive.com&#x2f;thankyou' />
          <Grid container spacing={3} alignItems="flex-start" alignContent="flex-start">
            <Grid item xs={12} sm={8}>
              <TextField
                id="company"
                label="Company"
                variant="outlined"
                maxLength="100"
                className={classes.formControl}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="contact-type-label">I am a:</InputLabel>
                <Select
                  labelId="contact-type-label"
                  id="contact-type"
                  name="LEADCF4"
                  value={contactType}
                  onChange={handleChange}
                  label="I am a:"
                >
                  <MenuItem value="Affiliate">Lender</MenuItem>
                  <MenuItem value="Advertiser">Advertiser</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="firstName"
                label="First Name"
                variant="outlined"
                maxLength="40"
                className={classes.formControl}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="lastName"
                label="Last Name"
                variant="outlined"
                maxLength="80"
                className={classes.formControl}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                label="E-Mail"
                variant="outlined"
                maxLength="250"
                className={classes.formControl}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="phone"
                label="Phone"
                variant="outlined"
                maxLength="30"
                className={classes.formControl}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                className={classes.formControl}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="enterdigest"
                name="enterdigest"
                label="Enter the characters you see"
                variant="outlined"
                maxLength="30"
                className={classes.formControl}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.verticalAlign}>
                <img id='imgid' alt='Captcha' src='https://crm.zoho.com/crm/CaptchaServlet?formId=c75a31430ced4b17780d29e0c5c4ff567e054ce2b2ef8c7016f602dc132a6fc8&grpid=f61c1e83dc842d254a4de264854d9f75b7590209d9a6b6c13305b921e2e11a69' />
                <Button 
                  component="a" 
                  variant="outlined"
                  size="small"
                  data-reload-captcha
                >
                  Reload
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Button 
                  variant="contained" 
                  color="primary"
                  size="large"
                  type="submit" defaultValue="submit"
                  >
                  send message
                </Button>
              </Box>
            </Grid>
        </Grid>
      </form>
    </div>  
  )
}


export default ContactForm