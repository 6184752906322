import PropTypes from "prop-types"
import React from "react"
import SwipeableViews from 'react-swipeable-views'

import theme from '../styles/theme'
import Border from '../components/border'

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Tabs,
  Tab,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core"

import Background02 from '../images/performance02.jpg'
import Background03 from '../images/speed01.jpg'
import Background04 from '../images/success01.jpg'
import Background05 from '../images/amp-up01.jpg'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `affiliate-full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textAlign: 'left',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    color: '#fff',
    marginRight: 0,
    minWidth: 'auto',
    minHeight: 'auto',
    padding: 0,
    
    '&:focus': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      opacity: 1,
    },
    '& > span': {
      alignItems: 'start',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '100%',
    },
  },
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
    '& .MuiGrid-item': {
      order: 1,
      '&:first-of-type': {
        order: 2,
      },
      [theme.breakpoints.up('sm')]: {
        order: 2,
        '&:first-of-type': {
          order: 1,
        },
      },
    },
  },
  container: {
    overflow: 'hidden',
  },
  background02:{
    backgroundImage:'url(' + Background04 + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  background03:{
    backgroundImage:'url(' + Background05 + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  background04:{
    backgroundImage:'url(' + Background02 + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  background05:{
    backgroundImage:'url(' + Background03 + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  backgroundOverlay: {
    background: 'rgba(0,0,0,0.66)',
    textShadow: '2px 4px 3px rgba(0,0,0,0.66)',
    overflow: 'hidden',
  },
}));


const Affiliate = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  function TabNavigation() {
    return (
      <Box
          className={classes.backgroundOverlay}
          padding={2}
        >
        <Typography
          variant="subtitle1"
          gutterBottom
        >
          Accelerated Earnings
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
        >
          Click the links below to learn more about...
        </Typography>
        <StyledTabs
          value={value}
          onChange={handleChange}
          orientation="vertical"
          aria-label="Features for Affiliates"
          id={"affiliate-features"}
        >
          <StyledTab label="&bull; Highly Available Architecture" {...a11yProps(0)} />
          <StyledTab label="&bull; Optimized for Speed" {...a11yProps(1)} />
          <StyledTab label="&bull; Price Reject Support" {...a11yProps(2)} />
          <StyledTab label="&bull; Auction 2.0 for Affiliates" {...a11yProps(3)} />
        </StyledTabs>
      </Box>
    );
  }

  function TabSlideContainer({children}) {
    return (
      <Box
        className={classes.backgroundOverlay}
        paddingY={{xs: 8, sm: 16, md: 32}}
      >
        <Container component="main" maxWidth="lg">
          <div className={classes.grid}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5} md={4}>
                <TabNavigation />
              </Grid>
              <Grid item xs={12} sm={7} md={8}>
                <Box 
                  paddingTop={{xs: 0, sm: 1}}
                  paddingRight={{xs: 0, sm: 5}}
                >
                  {children}
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Box>
    );
  }


  return (
    <Box
      bgcolor="secondary.main"
      color="secondary.contrastText"
      position="relative"
    >
      <Border type="topRight" />
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.container}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box
            bgcolor="secondary.main"
            color="secondary.contrastText"
            className={classes.background02}
          >
            <TabSlideContainer>
              <Typography
                variant="h3"
                gutterBottom
              >
                Highly Available Architecture
              </Typography>
              <Typography
                variant="body1" 
                paragraph
              >
                Fault Tolerance and Elasticity are a key feature of <strong>every</strong> application component.
              </Typography>
              <Typography 
                component="ul" 
                variant="body1" 
                paragraph
              >
                <li>
                  Leveraging the power of Amazon Web Services, every application component is available in multiple AWS Availability Zones.
                </li>
                <li>
                  Generate consistent and dependable earnings, day and night.
                </li>
                <li>
                  High volume is never a problem! Under heavy volumes, AMP  auto-scales to meet demand.
                </li>
              </Typography>
            </TabSlideContainer> 
          </Box>
          
        </TabPanel>


        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box
            bgcolor="secondary.main"
            color="secondary.contrastText"
            className={classes.background03}
          >
            <TabSlideContainer>
              <Typography
                variant="h3"
                gutterBottom
              >
                Optimized for Speed
              </Typography>
              <Typography
                variant="body1" 
                paragraph
              >
                You need leads accepted within seconds, and we've got you covered!
              </Typography>
              <Typography 
                component="ul" 
                variant="body1" 
                paragraph
              >
                <li>
                  AMP can execute business rules on thousands of campaigns in 300 milliseconds.
                </li>
                <li>
                  The new Auction 2.0 protocol allows us to maximize your earnings no matter how much time you have available.
                </li>
                <li>
                  AMP is incredible at multitasking and takes advantage of the latest parallelization techniques to get bids and find a buyer faster than any other platform you’ve integrated with before.
                </li>
              </Typography>
            </TabSlideContainer> 
          </Box>
        </TabPanel>


        <TabPanel value={value} index={2} dir={theme.direction}>
          <Box
            bgcolor="secondary.main"
            color="secondary.contrastText"
            className={classes.background04}
          >
            <TabSlideContainer>
              <Typography
                variant="h3"
                gutterBottom
              >
                Price Reject API
              </Typography>
              <Typography
                variant="body1" 
                paragraph
              >
                With more and more lead sellers using their own ping-tree platforms, AMP is a perfect solution for maximizing your revenue. Every lead seller that has tried our new Price Reject API has reported an increase in their revenue per lead.
              </Typography>
              <Typography 
                component="ul" 
                variant="body1" 
                paragraph
              >
                <li>
                  Increase your earnings by capturing additional offers from our bidding platform.
                </li>
                <li>
                  Save valuable time with a protocol widely used by lead buyers.
                </li>
                <li>
                  We continuously optimize bid redemption rates, ensuring maximum value.
                </li>
              </Typography>
            </TabSlideContainer> 
          </Box>
        </TabPanel>


        <TabPanel value={value} index={3} dir={theme.direction}>
          <Box
            bgcolor="secondary.main"
            color="secondary.contrastText"
            className={classes.background05}
          >
            <TabSlideContainer>
              <Typography
                variant="h3"
                gutterBottom
              >
                Auction 2.0 for Affiliates
              </Typography>
              <Typography
                variant="body1" 
                paragraph
              >
                We designed the industry’s first lead buyer auction over ten years ago, and now we're making it available to all lead sellers as well.
              </Typography>
              <Typography 
                component="ul"
                variant="body1" 
                paragraph
              >
                <li>
                  Integrate with a trustworthy bidding platform and experience a new way to transact.
                </li>
                <li>
                  In seconds, we can generate bids from lead buyers and offer you the highest price right away.
                </li>
                <li>
                  A faster consumer experience yields better redirect rates and better outcomes for all participants.
                </li>
              </Typography>
            </TabSlideContainer> 
          </Box>
        </TabPanel>
      </SwipeableViews>
      <Border type="bottomRight" />
    </Box>
  )
  
}



export default Affiliate
