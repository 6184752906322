import PropTypes from "prop-types"
import React from "react"
import SwipeableViews from 'react-swipeable-views'

import theme from '../styles/theme'
import Border from '../components/border'

import {
  Box,
  Container,
  Grid,
  makeStyles,
  Tabs,
  Tab,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core"

import Background02 from '../images/amp-up02.jpg'
import Background03 from '../images/performance01.jpg'
import Background04 from '../images/speed02.jpg'
import Background05 from '../images/success02.jpg'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `lender-full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textAlign: 'left',
    textTransform: 'none',
    color: '#fff',
    marginRight: 0,
    minWidth: 'auto',
    minHeight: 'auto',
    padding: 0,
    
    '&:focus': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      opacity: 1,
    },
    '& > span': {
      alignItems: 'start',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '100%',
    },
  },
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
    '& .MuiGrid-item': {
      order: 1,
      '&:first-of-type': {
        order: 2,
      },
    },
  },
  container: {
    overflow: 'hidden',
  },
  background02:{
    backgroundImage:'url(' + Background02 + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  background03:{
    backgroundImage:'url(' + Background03 + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  background04:{
    backgroundImage:'url(' + Background04 + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  background05:{
    backgroundImage:'url(' + Background05 + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  backgroundOverlay: {
    background: 'rgba(0,0,0,0.66)',
    textShadow: '2px 4px 3px rgba(0,0,0,0.66)',
    overflow: 'hidden',
  },
}));


const Lender = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function TabNavigation() {
    return (
      <Box
        className={classes.backgroundOverlay}
        padding={2}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
        >
          Accelerated Conversions
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
        >
          Click the links below to learn more about...
        </Typography>
        <StyledTabs
          value={value}
          onChange={handleChange}
          orientation="vertical"
          aria-label="Lender Features"
        >
          <StyledTab label="&bull; Experiments API" {...a11yProps(0)} />
          <StyledTab label="&bull; Price Reject" {...a11yProps(1)} />
          <StyledTab label="&bull; Auction 2.0" {...a11yProps(2)} />
          <StyledTab label="&bull; Advanced Optimization" {...a11yProps(3)} />
        </StyledTabs>
      </Box>
    );
  }

  function TabSlideContainer({children}) {
    return (
      <Box
        className={classes.backgroundOverlay}
        paddingY={{xs: 8, sm: 16, md: 32}}
      >
        <Container component="main" maxWidth="lg">
          <div className={classes.grid}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5} md={4}>
                <TabNavigation />
              </Grid>
              <Grid item xs={12} sm={7} md={8}>
                <Box 
                  paddingTop={{xs: 0, sm: 1}}
                  paddingLeft={{xs: 0, sm: 5}}
                >
                  {children}
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Box>
    );
  }


  return (
    <Box
      bgcolor="secondary.main"
      color="secondary.contrastText"
      position="relative"
    >
      <Border type="topRight" />
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        className={classes.container}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box
            bgcolor="secondary.main"
            color="secondary.contrastText"
            className={classes.background02}
          >
            <TabSlideContainer>
              <Typography
                variant="h3"
                gutterBottom
              >
                Experiments API
              </Typography>
              <Typography
                variant="body1" 
                paragraph
              >
                <strong>Have a Theory? Let us test it.</strong>
              </Typography>
              <Typography 
                component="ul" 
                variant="body1" 
                paragraph
              >
                <li>
                  Test the impact of price on volume and conversion metrics.
                </li>
                <li>
                  Test new segments and business rules to see if they work for your portfolio.
                </li>
                <li>
                  Test Auction 2.0 vs. Price Reject. Let the results speak for themselves!
                </li>
              </Typography>
            </TabSlideContainer> 
          </Box>
        </TabPanel>


        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box
            bgcolor="secondary.main"
            color="secondary.contrastText"
            className={classes.background03}
          >
            <TabSlideContainer>
              <Typography
                variant="h3"
                gutterBottom
              >
                Price Reject
              </Typography>
              <Typography
                variant="body1" 
                paragraph
              >
                <strong>Our top affiliates all support Price Reject protocols.</strong>
              </Typography>
              <Typography 
                component="ul" 
                variant="body1" 
                paragraph
              >
                <li>
                  If you make a bid, we can help get it closed.
                </li>
                <li>
                  Bid loss reporting. Learn how to win the lead next time!
                </li>
                <li>
                  <span style={{ color: theme.palette.primary.main }}><strong>NEW!</strong></span> Bid Buffers -- AMP can auto-adjust your bids based on your specific criteria to make sure you always win high-value leads.
                </li>
              </Typography>
            </TabSlideContainer>
          </Box>
        </TabPanel>


        <TabPanel value={value} index={2} dir={theme.direction}>
          <Box
            bgcolor="secondary.main"
            color="secondary.contrastText"
            className={classes.background04}
          >
            <TabSlideContainer>
              <Typography
                variant="h3"
                gutterBottom
              >
                Auction 2.0
              </Typography>
              <Typography
                variant="body1" 
                paragraph
              >
                <strong>Faster is better. Let us prove it to you.</strong>
              </Typography>
              <Typography
                  component="ul"
                  variant="body1"
                  paragraph
              >
                <li>
                  Better contact rates. Better conversion rates. Better portfolio results.
                </li>
                <li>
                  First looks at every lead that matches your filters. Tune your models with access to premium inventory. See the leads you aren't winning and learn how to win those leads next time.
                </li>
                <li>
                  <span style={{ color: theme.palette.primary.main }}><strong>NEW!</strong></span> Bid Buffers -- AMP can auto-adjust your bids based on your specific criteria to make sure you always win high-value leads.
                </li>
              </Typography>
            </TabSlideContainer>
          </Box>
        </TabPanel>


        <TabPanel value={value} index={3} dir={theme.direction}>
          <Box
            bgcolor="secondary.main"
            color="secondary.contrastText"
            className={classes.background05}
          >
            <TabSlideContainer>
              <Typography
                variant="h3"
                gutterBottom
              >
                Advanced Optimization
              </Typography>
              <Typography
                variant="body1" 
                paragraph
              >
                Deep Learning. ML. Advanced Business Intelligence.
              </Typography>
              <Typography 
                component="ul" 
                variant="body1" 
                paragraph
              >
                <li>
                  Provide us with outcome data. We'll find the leads that work.
                </li>
                <li>
                  Give us your weekly lead or funded volume needs and a performance goal. We’ll take care of the rest.
                </li>
                <li>
                  Need insights? Our state of the art BI platform has more than you knew you needed.
                </li>
              </Typography>
            </TabSlideContainer>
          </Box>
        </TabPanel>
      </SwipeableViews>
      <Border type="bottomRight" />
    </Box>
  )
  
}



export default Lender
