import React, {useRef} from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"
import Border from "../components/border"
import ContactForm from "../components/contactForm"
import Lender from "../components/lender"
import Affiliate from "../components/affiliate"


import {
  Box,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core"
import theme from "../styles/theme"


const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  }, 
}));

const IndexPage = () => {
  const classes = useStyles();
  const affiliateSliderRef = useRef(null)
  const executeAffiliateScroll = () => affiliateSliderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const scrollToAffiliateSection = (e, index) => {
    document.getElementById('affiliate-full-width-tab-' + index).click();
    executeAffiliateScroll();
    e.preventDefault();
  }
  const lenderSliderRef = useRef(null)
  const executeLenderScroll = () => lenderSliderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const scrollToLenderSection = (e, index) => {
    document.getElementById('lender-full-width-tab-' + index).click();
    executeLenderScroll();
    e.preventDefault();
  }

  
  return (
    <Layout>
      <SEO title="The NEW Accelerated Marketplace by Acquire Interactive" />
      <Box 
        id="affiliates"
        bgcolor="background.default"
        paddingY={{xs: 7, sm: 10, md: 14}}
        position="relative"
        style={{zIndex: 100}}
      >
        <Container component="main" maxWidth="md">
          <Typography
              variant="h2"
              align="center"
              gutterBottom
          >
            <span style={{ color: theme.palette.primary.main }}>AMP</span> for Lead Sellers
          </Typography>
          <Typography
              variant="body1"
              align="center"
              paragraph
          >
            <strong>The Accelerated Marketplace</strong> is a <Link href="#" onClick={(e) => scrollToAffiliateSection(e, 0) } underline="always">highly available</Link> lead placement API, optimized from the ground up for <Link href="#" onClick={(e) => scrollToAffiliateSection(e, 1) } underline="always">speed</Link> and revenue maximization.
            AMP runs thousands of business rules in fractions of a second, optimizing the time available to create revenue events.
            The platform supports dynamic tier structures of any kind, making it easier than ever to integrate and start selling quickly!
            The new <Link href="#" underline="always" onClick={(e) => scrollToAffiliateSection(e, 2) }>Price Reject</Link>,
            and <Link href="#" underline="always" onClick={(e) => scrollToAffiliateSection(e, 3) }>Auction 2.0</Link> APIs allow us to find buyers with bids for your leads rapidly.

          </Typography>
          <Typography
              variant="body1"
              align="center"
          >
            Integration Specs: <Link href="https://api.acquireinteractive.com/info/posting" target="_blank" underline="always">Loan API</Link> | <Link href="https://api.acquireinteractive.com/info/amp-docs" target="_blank" underline="always">Auction 2.0</Link>
          </Typography>
        </Container>
      </Box>
      <div ref={affiliateSliderRef}>
        <Affiliate />
      </div>
      <Box
        id="lenders"
        bgcolor="background.default"
        paddingY={{xs: 7, sm: 10, md: 14}}
        position="relative"
        style={{zIndex: 100}}
      >
        <Container component="main" maxWidth="md">
          <Typography
            variant="h2"
            align="center"
            gutterBottom
          >
            <span style={{ color: theme.palette.primary.main }}>AMP</span> for Lead Buyers
          </Typography>
          <Typography 
            variant="body1" 
            align="center"
          >
            <strong>The Accelerated Marketplace</strong> provides premium lead inventory and a rich feature set that lead buyers can use to maximize conversions and value.
            Our <Link href="#" underline="always" onClick={(e) => scrollToLenderSection(e, 0) }>Experiments API</Link> allows lead buyers to quickly A/B test any market hypothesis, with use-cases like rules, filters, price points, and API protocols.
            Lenders with bidding models will enjoy increased fulfillment with our affiliate <Link href="#" underline="always" onClick={(e) => scrollToLenderSection(e, 1) }>Price Reject</Link> and Auction 2.0 APIs.
            The <Link href="#" underline="always" onClick={(e) => scrollToLenderSection(e, 2) }>Auction 2.0 Bidding Platform</Link> provides access to all lead inventory, delivered within seconds. Our lead buyers consistently report better conversion rates and back-end performance.
            Our <Link href="#" underline="always" onClick={(e) => scrollToLenderSection(e, 3) }>Advanced Optimization</Link> capabilities allow lead buyers to import lead outcome data for lead optimization using deep learning and advanced business intelligence.
          </Typography>
        </Container>
      </Box>
      <div ref={lenderSliderRef}>
        <Lender />
      </div>
      <Box 
        bgcolor="background.default"
        position="relative"
        style={{zIndex: 100}}
      >
        <Container component="main" maxWidth="md">
          <Box 
            paddingY={{xs: 7, sm: 10, md: 14}}
          >
            <Typography
              variant="h2"
              align="center"
              gutterBottom
            >
              Let's Work Together.
            </Typography>
            <div className={classes.grid}>
              <Grid container>
                <Grid item md={9}>
                  <ContactForm />
                </Grid>
                <Grid item md={3}>
                  <Box paddingLeft={{xs: 0, md: 5}}>
                    <Typography variant="subtitle1">
                      Email
                    </Typography>
                    <Typography variant="body1" paragraph>
                      <Link href="mailto:info@acquireinteractive.com" color="inherit">info@acquireinteractive.com</Link>
                    </Typography>

                    <Typography variant="subtitle1">
                      Phone
                    </Typography>
                    <Typography variant="body1">
                      <Link href="callto:+1-800-454-5904" color="inherit">1-800-454-5904</Link>
                    </Typography>
                    <Typography variant="body1" paragraph>
                      <Link href="callto:+1-702-407-0707" color="inherit">1-702-407-0707</Link>
                    </Typography>
                    
                    <Typography variant="subtitle1">
                      Address
                    </Typography>
                    <Typography variant="body1" paragraph>
                      325 East Warm Springs Rd<br />
                      Suite 101<br />
                      Las Vegas, NV 89119
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Container>
        <Border type="bottomLeft" fill="#343434" />
      </Box>
    </Layout>
  )
}
export default IndexPage
